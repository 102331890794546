import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/tailwind.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faUser, faPhone, faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { createHead } from "@unhead/vue"

const head = createHead()

library.add(faEnvelope, faUser, faPhone, faBars, faXmark)
createApp(App).use(router).use(head).mount('#app')
