<!-- Modified version of https://github.com/2alheure/safe_mail -->
<template>
  <small>
    <a
      class="safe-mail"
      :href="'mailto:' + reversed"
      @click="mailClicked">
      {{ reversed }}
    </a>
  </small>
</template>

<script>
export default {
  name: "SafeMailComponent",
  props: {
    email: String,
  },
  data() {
    return {
      emailAlreadyClicked: false,
    };
  },
  computed: {
    reversed() {
      return this.email.split("").reverse().join("");
    },
  },
  methods: {
    mailClicked(e) {
      if (!this.emailAlreadyClicked) {
        e.preventDefault;
        this.emailAlreadyClicked = true;
        e.target.href = "mailto:" + this.email;
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
a.safe-mail {
  unicode-bidi: bidi-override;
  direction: rtl;
}
</style>
