import { createWebHistory, createRouter } from "vue-router";

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/components/HomePage"),
    },
    {
        path: "/contact",
        name: "Contact",
        component: () => import("@/components/ContactPage"),
    },
    {
        path: "/insurances",
        name: "Insurances",
        component: () => import("@/components/InsurancesPage"),
    },
    {
        path: "/individual",
        name: "Individual",
        component: () => import("@/components/IndividualPage"),
    },
    {
        path: "/family",
        name: "Family",
        component: () => import("@/components/FamilyPage"),
    },
    {
        path: "/couples",
        name: "Couples",
        component: () => import("@/components/CouplesPage"),
    },
    {
        path: "/telehealth",
        name: "Telehealth",
        component: () => import("@/components/TelehealthPage"),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        // eslint-disable-next-line
        redirect: to => {
            return { path: '/'}
        },
    },
    {
        path: '/:pathMatch(.*)',
        name: 'bad-not-found',
        // eslint-disable-next-line
        redirect: to => {
            return { path: '/'}
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return new Promise((resolve) => {
            resolve({ left: 0, top: 0 })
        })
    }
});

export default router;
