<template>
  <div>
    <RouterLink to="/">
      <picture>
        <source type="image/webp" srcset="./../assets/img/logo-no-text.webp">
        <img src="./../assets/img/logo-no-text.png" alt="Hummingbird Therapy" class="absolute top-0 left-0 w-16 m-6 md:hover:opacity-75">
      </picture>
    </RouterLink>

    <FontAwesomeIcon v-if="showMenu"
                     @click="toggleNav"
                     icon="xmark"
                     class="visible md:invisible absolute top-0 right-0 h-8 mr-10 mt-10 cursor-pointer"/>

    <FontAwesomeIcon v-else
                     @click="toggleNav"
                     icon="bars"
                     class="visible md:invisible absolute top-0 right-0 h-8 mr-10 mt-10 cursor-pointer"/>

    <nav :class="showMenu ? 'flex flex-col mx-auto space-y-4 md:space-y-0 mt-8 mb-8 h-full': 'invisible h-8'"
         class="md:visible md:m-4 md:grid md:grid-cols-5 md:gap-1 md:h-8 lg:h-5">

      <RouterLink to="/" @click="toggleNav" class="hover:text-gray-500 ml-2">
        Home
      </RouterLink>

      <RouterLink to="/insurances" @click="toggleNav" class="hover:text-gray-500 ml-2">
        Accepted Insurances
      </RouterLink>

      <RouterLink to="/individual" @click="toggleNav" class="block md:hidden hover:text-gray-500 ml-2">
         Individual Therapy
      </RouterLink>
      <RouterLink to="/family" @click="toggleNav" class="block md:hidden hover:text-gray-500 ml-2">
          Family Therapy
      </RouterLink>
      <RouterLink to="/couples" @click="toggleNav" class="block md:hidden hover:text-gray-500 ml-2">
          Couples Therapy
      </RouterLink>
      <RouterLink to="/telehealth" @click="toggleNav" class="block md:hidden hover:text-gray-500 ml-2">
          Telehealth
      </RouterLink>
      <div class="hidden md:block">
        <ServicesDropdownComponent/>
      </div>

      <a href="https://lindsey-trujillo.clientsecure.me"
         class="hover:text-gray-500 ml-2"
         rel="noopener noreferrer"
         target="_blank"
         @click="toggleNav"
         data-spwidget-scope-id="6f5e1131-33f7-45bd-bb22-a5b78fe7f6bd"
         data-spwidget-scope-uri="lindsey-trujillo"
         data-spwidget-application-id="7c72cb9f9a9b913654bb89d6c7b4e71a77911b30192051da35384b4d0c6d505b"
         data-spwidget-scope-global
         data-spwidget-autobind>
        Schedule Appointment
      </a>

      <RouterLink to="/contact" @click="toggleNav" class="hover:text-gray-500 ml-2">
        Contact
      </RouterLink>

    </nav>
    <div class="md:pt-1 text-gray-500 gradient-underline-header mb-10"/>
  </div>
</template>

<script>
import {ref} from 'vue';
import ServicesDropdownComponent from "@/components/ServicesDropdownComponent";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { useWindowSize } from 'vue-window-size';


export default {
  name: "HeaderComponent",
  components: {
    ServicesDropdownComponent,
    FontAwesomeIcon
  },
  setup() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  async mounted() {
    let clientSecureScript = document.createElement('script')
    clientSecureScript.setAttribute('defer', 'defer')
    clientSecureScript.setAttribute('src', 'https://widget-cdn.simplepractice.com/assets/integration-1.0.js')
    document.head.appendChild(clientSecureScript)
  },
  data: function () {
    return {
      showMenu: ref(false)
    }
  },
  methods: {
    toggleNav() {
      this.showMenu = this.windowWidth < 768 ? !this.showMenu : this.showMenu
    }
  }
}
</script>
